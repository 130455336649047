import "./projects.scss"

import { Col, Row } from "react-simple-flex-grid"

import Footer from "../components/footer"
import Image from "../components/image"
import Layout from "../components/layout"
import Link from "../components/link"
import React from "react"
import SEO from "../components/seo"
import TopSection from "../components/top-section"
import projects from "../content/projects.json"
import { useIntl } from "gatsby-plugin-intl"

function Projects() {
  const intl = useIntl()
  const keywords = projects.map((x) => x.id)

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "projects.title" })} keywords={keywords} />
      <div className="Projects">
        <Row className="TopSection__Separator">
          <TopSection title={intl.formatMessage({ id: "projects.title" })} />

          <Col xs={12} className="Text__Light TopSection__Separator">
            <p className="Text__H3 Text__Lighten">{intl.formatMessage({ id: "projects.desc1" })}</p>
            <p className="Text__H3 Text__Lighten">{intl.formatMessage({ id: "projects.desc2" })}</p>
          </Col>
        </Row>

        {/* Projects Grid */}
        <Row className="Text__Darken Projects__Content">
          {projects
            .filter((x) => !x.hidden)
            .map((x, idx) => (
              <Col key={"col" + x.id} xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: idx % 2 ? 2 : 1 }}>
                <Link paint to={"/project/" + x.id} className={"Text__H2 Project__Item"}>
                  {x.heroVideo ? (
                    <video
                      className={`Project__Item__Img Image__${x.id.toUpperCase()}`}
                      autoPlay
                      muted
                      loop
                      style={{ maxWidth: "100%" }}
                    >
                      <source src={x.heroVideo} type="video/mp4" />
                    </video>
                  ) : (
                    <Image
                      className={`Project__Item__Img Image__${x.id.toUpperCase()}`}
                      filename={x.id + "/hero.png"}
                      alt={x.title}
                    />
                  )}

                  <span className={"Project__Item__Title Text__H2 Text__Light"}>{x.title}</span>
                  <div className={"Project__Item__Type Text__H4 Text__Bold "}>{x.type}</div>
                </Link>
              </Col>
            ))}
        </Row>
        <Footer />
      </div>
    </Layout>
  )
}

export default Projects
